import React, { useState, useEffect } from "react";
import "./App.scss";
import { redirectToRegion, getCountryCodeByIp } from "helpers/helpers";
import StickySocial from "./Components/Common/StickySocial/StickySocial";
import MainSection from "./Components/MainSection/MainSection";
import WhoWeAre from "./Components/WhoWeAre/WhoWeAre";
import Partners from "./Components/Partners/Partners";
import Experience from "./Components/Experience/Experience";
import ApplicationProcess from "./Components/ApplicationProcess";
import Learn from "./Components/Learn/Learn";
import Tuition from "./Components/Tuition/Tuition";
import Footer from "./Components/Footer/Footer";
import ApplyFormWrapper from "./Components/ApplyForm/ApplyFormWrapper";
import CookieConsent from "Components/CookieConsent/CookieConsent";
import CookiePolicy from "Components/CookiePolicy/CookiePolicy";
import Financing from "Components/Financing/Financing";

function App() {
  const [isApplyFormVisible, setIsApplyFormVisible] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(
    JSON.parse(localStorage.cookieConsentApproved || "true")
  );
  const [shouldShowCookiePolicy, setShouldShowCookiePolicy] = useState(
    window.location.pathname === "/cookie-policy"
  );

  const showCookiePolicy = () => {
    setShouldShowCookiePolicy(true);
    window.history.pushState({}, "", "/cookie-policy");
  };

  useEffect(() => {
    const init = async () => {
      try {
        const countryCode = await getCountryCodeByIp();
        redirectToRegion(countryCode);
      } catch (error) {
        console.log(error);
      }
      // enable browser back button
      window.onpopstate = (e) =>
        setShouldShowCookiePolicy(
          window.location.pathname === "/cookie-policy"
        );
    };

    init();
  }, []);

  const approveCookieConsent = () => {
    setShowCookieConsent(false);
    localStorage.cookieConsentApproved = false;
  };

  const applyFormVisibilityHandler = () => {
    setIsApplyFormVisible(!isApplyFormVisible);
    document.querySelector("body").classList.toggle("pageScrollDisable");
  };

  if (shouldShowCookiePolicy)
    return (
      <div className="App">
        <CookiePolicy closePage={() => setShouldShowCookiePolicy(false)} />
      </div>
    );

  return (
    <div className="App">
      <StickySocial />
      <MainSection applyFormVisibilityHandler={applyFormVisibilityHandler} />
      <WhoWeAre />
      <Partners />
      <Experience applyFormVisibilityHandler={applyFormVisibilityHandler} />
      <Learn />
      <ApplicationProcess
        applyFormVisibilityHandler={applyFormVisibilityHandler}
      />
      <Tuition applyFormVisibilityHandler={applyFormVisibilityHandler} />
      <Financing applyFormVisibilityHandler={applyFormVisibilityHandler} />
      <Footer />
      {isApplyFormVisible && (
        <ApplyFormWrapper
          applyFormVisibilityHandler={applyFormVisibilityHandler}
        />
      )}

      {showCookieConsent && (
        <CookieConsent
          onConfirm={approveCookieConsent}
          showCookiePolicy={showCookiePolicy}
        />
      )}
    </div>
  );
}

export default App;
