import React from "react";
import { application, tuition } from "content.js";
import * as styles from "./ApplicationProcess.module.scss";

import Step from "./Step/Step";
import Button from "../Common/Button/Button";

const ApplicationProcess = ({ applyFormVisibilityHandler }) => {
  const { title, steps } = application;

  return (
    <div className={`${styles.application}`}>
      <div className="container">
        <h3 className={styles.title}>{title}</h3>
        <ul>
          {steps.map((step, i) => {
            const stepNum = i + 1;
            return (
              <Step key={`step-${stepNum}`} step={step} stepNum={stepNum} />
            );
          })}
        </ul>
        <div className={`container ${styles.reserve}`}>
          <Button onClick={applyFormVisibilityHandler}>
            {tuition.priceSection.ctaText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationProcess;
